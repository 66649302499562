import {
  mdiAccountCheckOutline, mdiAccountCogOutline,
  mdiAccountOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'CONFIGURATIONS',
  },
  {
    title: 'Données',
    icon: mdiAccountOutline,
    /*resource: 'Configuration',*/
    /*action: 'read',*/
    children: [
      {
        title: 'Activités',
        to: 'activities',
        resource: 'Activities',
        action: 'read',
      },
      {
        title: 'Sources auteurs',
        to: 'sources',
        resource: 'Sources',
        action: 'read',
      },
      {
        title: 'Cas particuliers',
        to: 'particular_cases',
        resource: 'particular_cases',
        action: 'read',
      },
      {
        title: 'Statuts',
        to: 'statuses',
        resource: 'Statuts',
        action: 'read',
      },
      {
        title: 'Positions de dossiers',
        to: 'states',
        resource: 'States',
        action: 'read',
      },
      {
        title: 'Tarifications',
        to: 'tarifications',
        resource: 'Tarifications',
        action: 'read',
      },
      {
        title: 'Formats',
        to: 'formats',
        resource: 'Formats',
        action: 'read',
      },
      {
        title: 'Genres',
        to: 'genres',
        resource: 'Genres',
        action: 'read',
      },
      {
        title: 'Origines',
        to: 'origins',
        resource: 'Origins',
        action: 'read',
      },
      {
        title: 'Pays',
        to: 'countries',
        resource: 'Countries',
        action: 'read',
      },
      {
        title: 'Codes Couleurs',
        to: 'colors',
        resource: 'Colors',
        action: 'read',
      },

      /* {
        title: 'Settings',
        to: 'settings',
        resource: 'Public',
        action: 'read',
      }, */
      {
        title: 'Configurations',
        to: 'configurations',
        resource: 'Configurations',
        action: 'read',
      },
      {
        title: 'Tâches',
        to: 'tasks',
        resource: 'Tasks',
        action: 'read',
      },
      {
        title: 'Destinations',
        to: 'destinations',
        resource: 'Destination',
        action: 'read',
      },
      {
        title: 'Imprimeurs',
        to: 'printers',
        resource: 'Printer',
        action: 'read',
      },
      {
        title: 'Type d\'auteur',
        to: 'author_types',
        resource: 'AuthorType',
        action: 'read',
      },
      {
        title: 'Informations particulières',
        to: 'author_states',
        resource: 'AuthorState',
        action: 'read',
      },
      {
        title: 'Réseaux sociaux',
        to: 'networks',
        resource: 'Network',
        action: 'read',
      },
      {
        title: 'Professions',
        to: 'professions',
        resource: 'Profession',
        action: 'read',
      },
      {
        title: 'Types de médias',
        to: 'media_types',
        resource: 'MediaType',
        action: 'read',
      },
      {
        title: 'Types d\'événements',
        to: 'event_types',
        resource: 'EventType',
        action: 'read',
      },
    ],
  },
  {
    title: 'Utilisateurs',
    icon: mdiAccountOutline,
    to: 'users',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Rôles',
    icon: mdiAccountCogOutline,
    to: 'roles',
    resource: 'Role',
    action: 'read',
  },
  {
    title: 'Permissions',
    icon: mdiAccountCheckOutline,
    to: 'permissions',
    resource: 'Permission',
    action: 'read',
  },
]
